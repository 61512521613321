import React from "react";
import { createRoot } from 'react-dom/client';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useRouteError } from "react-router-dom";
//import mixpanel from "mixpanel-browser";
import MainLayout from "../layouts/MainLayout";
import { Chapter } from "../components/Chapter";
import { ChapterPicker } from "../components/ChapterPicker";
import { About } from "../components/About";

mixpanel.init("4e29171b49c577ade66edd26400d654e", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  const message = error?.status == 404 ? "404 Not Found" : error.statusText || error.message

  return (
    <div id="error-page">
      <h1>{message}</h1>
    </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />} errorElement={<ErrorPage />}>
      <Route path="/chapters/:chapterId" element={<Chapter />} />
      <Route path="/chapters" element={<ChapterPicker />} />
      <Route path="about" element={<About />} />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

const container = document.getElementById('reactRoot');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)